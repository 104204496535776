/* Reset Styles */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Remove default margin and padding*/
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 3rem;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
}
h2 {
  font-size: 2.3rem;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
}
h3 {
  font-size: 1rem;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  background: var(--color-layoutbg);
}

/* A elements that don't have a class get default styles */
a {
  text-decoration: none;
  color: #fff;
}

.solid__bg {
  background-color: var(--color-layoutbg);
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 1% 6%,
    rgba(167, 229, 176, 1) 0%,
    rgba(88, 125, 48, 1) 80%,
    rgba(66, 99, 29, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 1% 6%,
    rgba(167, 229, 176, 1) 0%,
    rgba(88, 125, 48, 1) 80%,
    rgba(66, 99, 29, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 1% 6%,
    rgba(167, 229, 176, 1) 0%,
    rgba(88, 125, 48, 1) 80%,
    rgba(66, 99, 29, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 1% 6%,
    rgba(167, 229, 176, 1) 0%,
    rgba(88, 125, 48, 1) 80%,
    rgba(66, 99, 29, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 1% 6%,
    rgba(167, 229, 176, 1) 0%,
    rgba(88, 125, 48, 1) 80%,
    rgba(66, 99, 29, 1) 100%
  );
}
.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section__padding {
  padding: 4rem 6rem;
}
.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}
@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
/* ----------------------------------------------
 animation
 * ---------------------------------------------- */
.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
