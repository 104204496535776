.mckw__who {
  display: flex;
  flex-direction: column;
  padding: 7rem;

  /* ff 3.6+ 
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #083d08 25%,
    rgba(88, 125, 48, 1) 85%,
    rgba(66, 99, 29, 1) 100%
  );

  /* safari 5.1+,chrome 10+ 
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #083d08 25%,
    rgba(88, 125, 48, 1) 85%,
    rgba(66, 99, 29, 1) 100%
  );

  /* opera 11.10+ 
  background: -o-radial-gradient(
    circle at 30% -100%,
    #083d08 25%,
    rgba(88, 125, 48, 1) 85%,
    rgba(66, 99, 29, 1) 100%
  );

  /* ie 10+ 
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #083d08 25%,
    rgba(88, 125, 48, 1) 85%,
    rgba(66, 99, 29, 1) 100%
  );*/

  /* global 92%+ browsers support 
  background: radial-gradient(
    circle at 30% -100%,
    #083d08 25%,
    rgba(88, 125, 48, 1) 85%,
    rgba(66, 99, 29, 1) 100%
  );*/
}

.mckw__who-feature {
  display: flex;
}

/* Customizing feature component as per needs */
.mckw__who-feature .mckw__features-container__feature {
  margin: 0;
}

/* Customizing feature component as per needs */
.mckw__who-feature .mckw__features-container_feature-text {
  max-width: 700px;
}
.mckw__who-heading-content {
  display: flex;
  flex-direction: row;
}

.mckw__who-heading {
  margin: 3rem;
}
.mckw__who-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mckw__who-image img {
  width: 250px;
}

.mckw__who-heading h2 {
  line-height: 40px;
  font-family: var(--font-family);
  max-width: 510px;
  color: var(--color-card);
}

.mckw__who-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 24px;
  color: var(--color-card);
  cursor: pointer;
  margin-top: 1rem;
}

.mckw__who-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 3rem;
}
@media screen and (max-width: 900px) {
  .mckw__who-heading-content {
    flex-direction: column;
  }
  .mckw__who {
    padding: 0;
  }
}
@media screen and (max-width: 500px) {
  .mckw__card-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    height: 100%;
    background-color: var(--color-card);
  }
  .mckw__card-container {
    margin: 2rem;
  }

  .mckw__contact {
    margin: 2rem;
  }
  .mckw__who-heading h2 {
    font-size: 1.7rem;
  }
}
