.mckw__header {
  height: 100%;
  padding-top: 2rem;
}
.mckw__header-content {
  color: #fff;
}
.mckw__header h1 {
  font-size: 60px;
  font-family: var(--font-family);
  color: var(--color-navbutlight);
}
.mckw__header-txtbgrnd {
  background-color: var(--color-bg);
  width: 625px;
  padding: 5rem;
  margin-top: 3rem;
  position: relative;
}
.mckw__header-image {
  width: 100%;
  margin-top: -10rem;
}
.mckw__header-image img {
  margin-top: 3rem;
  width: 100%;
  height: 100%;
}
.mckw__navbar-sign button:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}

@media screen and (max-width: 1050px) {
  .mckw__header {
    flex-direction: column;
  }
  .mckw__navbar-links_logo {
    z-index: 5000;
  }
}
@media screen and (max-width: 700px) {
  .mckw__header-content h1 {
    font-size: 45px;
  }
  .mckw__header-txtbgrnd {
    padding: 1rem 0 1rem 3.5rem;
    width: 470px;
  }
}
@media screen and (max-width: 500px) {
  .mckw__header-txtbgrnd {
    width: 100%;
  }
  .mckw__header h1 {
    font-size: 1.8rem;
    margin-left: -10px;
  }
  .mckw__header-image img {
    margin-top: 5rem;
  }
  .mckw__card-container_article-content h2 {
    font-size: 1.5rem;
  }
  mckw__contact_container_header h2 {
    text-align: center;
  }
}
