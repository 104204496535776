.mckw__card-container_article {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-layoutbg);
}
.mckw__card-container_article-image {
  width: 100%;
}
.mckw__card-container_article-image img {
  width: 100%;
  height: 100%;
}
.mckw__card-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  height: 100%;
  background-color: var(--color-card);
  margin-top: -10px;
}
.mckw__card-container_article-content p {
  font-family: var(--font-family);
  line-height: 24px;
  color: #fff;
  margin-top: 1rem;
}

.mckw__card-container_article-content h3 {
  font-family: var(--font-family);
  font-weight: 800;
  color: #9fe3aa;
  margin-bottom: 2rem;
}
@media screen and (max-width: 550px) {
  .mckw__card-container_article-content h3 {
    font-size: 18px;
    line-height: 25px;
  }
  .mckw__card-container_article-content h2 {
    font-size: 1.7rem;
  }
}
