.mckw__download-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/download.png);
  background-repeat: no-repeat;
  height: 200px;
  background-size: cover;
}
.mckw__download-btn {
  color: unset;
}
.mckw__download-image {
  color: unset;
}
.mckw__download-image img {
  color: unset;
}
.mckw__download-btn button {
  padding: 1.5rem;

  background: var(--color-card);
  font-family: var(--font-family);
  font-weight: 500;

  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}
.mckw__download-btn button:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}
.mckw__download-btn button a {
  font-size: 1.5rem;
}
