.mckw__footer {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: var(--color-card);
  color: var(--color-navbutlight);
  font-family: var(--font-family);
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  background-color: black;
}
.mckw__footer p {
  font-size: 10px;
}
.styles-stt {
  padding: 0;
  margin: 0;
  background-color: var(--color-navbutlightgreen);
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 7px;
  width: 55px;
  height: 45px;
  transition: opacity 1s ease-in-out;
  box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
  border: none;
  outline: none;
}
@media screen and (max-width: 500px) {
  .styles-stt {
    width: 35px;
    height: 40px;
    right: 25px;
  }
}

@media screen and (max-width: 500px) {
  .styles-stt {
    width: 35px;
    height: 40px;
  }
}
