.mckw__member_container {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  font-size: 1.2rem;
}
.mckw__member_header-primary {
  text-align: center;
}
.mckw__member_header-secondary {
  justify-content: flex-start;
  color: var(--color-card);
}
.mckw__member_header-primary h1 {
  color: var(--color-card);
  margin: 5rem 0;
  background-color: var(--color-navbutlightgreen);
}
.mckw__member_header-secondary h2 {
  color: var(--color-card);
  border-bottom: var(--color-card);
}

.mckw__member-boardmen-position {
  color: var(--color-card);
  font-size: 2rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  border-bottom: var(--color-card);
  border-block-style: solid;
  width: 300px;
  text-align: center;

  padding: 0 1rem;
}
.mckw__memeber-boardmen-name {
  margin-top: 0.25rem;
}

.mckw__member-boardmen-left {
  display: flex;
  flex-direction: column;
}
.mckw__member-presdients_container {
  display: flex;
  flex-direction: column;
  width: 85%;
  border-radius: 20px;
  background-color: var(--color-card);
  padding: 3rem;
}
.mckw__member-presdients_container h1 {
  color: var(--color-navbutlightgreen);
  margin-right: 1.5rem;
}
.mckw__member-presidents-header_container {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
}
.mckw__member-presidents-copy_container {
  display: flex;
  flex-direction: column;
}
.mckw__member-presidents-copy p {
  font-family: var(--font-family);
  color: #fff;
  font-weight: normal;
  line-height: 1.5rem;
  margin-top: 1.25rem;
}
.mckw__member-presidents-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.mckw__member-docs_container p {
  font-family: var(--font-family);
  font-size: 1.25rem;
}
.mckw__member-docs_container h3 {
  color: var(--color-card);
  font-size: 1.25rem;
}
.mckw__member-docs_container {
  padding: 4rem;
  text-align: center;
}
.mckw__member-docs_container ul {
  list-style-type: none;
  list-style-position: inside;
  font-family: var(--font-family);
  padding: 0;
  margin-top: 1rem;
}
.mckw__member-docs-list li {
  margin-top: 1rem;
}
.mckw__member-docs-list h2 {
  color: var(--color-card);
  font-size: 2rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  border-bottom: var(--color-card);
  border-block-style: solid;

  padding: 0 1rem;

  text-align: center;
}
.mckw__member-docs-list ul li a {
  margin-top: 1rem;
  color: var(--color-card);
  font-size: 1.2rem;
}
.mckw__member-docs-list ul li a:hover {
  color: grey;
}
button {
  padding: 0.25rem 1rem;
  color: var(--color-subtext);
  background: var(--color-navbutlightgreen);
  font-family: var(--font-family);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  width: 10%;
  font-size: 14px;
}

button:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}
.mckw__members-greeting {
  display: none;
  font-family: var(--font-family);
}
.mckw__members_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mckw__membersnavbar-wrapper {
  display: flex;
  flex-direction: row;
  background-color: pink;
}
.mckw__membutton-wrapper {
  background-color: pink;
  width: 20%;
}
.mckw__member-position-container {
  height: auto;
  background-color: pink;
}
.mckw__member-board-container {
  background-color: pink;
}
@media screen and (max-width: 1150px) {
  .mckw__member_header-primary {
    margin: 8rem 0;
  }
  .mckw__member_header-primary h1 {
    font-size: 2.2rem;
    margin: 8rem 0 4rem 0;
  }
  .mckw__member_header-secondary h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .mckw__member-presdients_container h1 {
    font-size: 2.3rem;
    margin-right: 0;
  }
  .mckw__member-presidents-header_container {
    flex-direction: column;
    text-align: center;
  }
  .mckw__member-presidents-wrapper {
    margin-top: 3rem;
  }
  .mckw__member-presidents-header_container img {
    margin-top: 2rem;
  }
  .mckw__member-docs-list h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 540px) {
  .mckw__member_header-primary {
    font-size: 2rem;
  }
  .mckw__member-presdients_container h1 {
    font-size: 2rem;
  }
  .mckw__member_header-secondary h2 {
    font-size: 1.5rem;
  }
  .mckw__member-boardmen-position {
    font-size: 1.5rem;
  }
  .mckw__member-docs-list h2 {
    font-size: 1.5rem;
  }
  .mckw__member-presidents-header_container img {
    width: 80%;
  }
}
