.mckw__login {
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mckw__wrapper {
  width: 60%;
  height: 80%;
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: var(--color-card);
  flex-direction: column;
  justify-content: center;
}

.mckw__button_wrapper.loginButton {
  width: 150px;
  padding: 15px 25px;
  border-radius: 20px;
  color: var(--color-navbut);
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 0.8rem;
}
.mckw__button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.google {
  background-color: white;
  font-family: var(--font-family);
}
.facebook {
  background-color: white;
  font-family: var(--font-family);
}
.instagram {
  background-color: white;
  font-family: var(--font-family);
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.mckw__login textrea {
  padding: 15px 20px;
  margin-bottom: 25px;
  border-radius: 20px;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
}

.submit {
  padding: 1rem;
  color: var(--color-subtext);
  background-color: var(--color-navbutlightgreen);
  font-family: var(--font-family);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  width: 70%;
  margin-top: 1rem;
  font-size: 1rem;
}

.submit:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}

.loginTitle {
  font-size: 1.8rem;
  top: 80px;
  color: white;
  font-family: var(--font-family);
  text-align: center;
  padding-bottom: 1rem;
}

.link {
  color: inherit;
  text-decoration: none;
}
.mckw__login-back-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  margin-top: 1rem;
}

div.fade.alert.alert-danger.show {
  color: red;
}

a {
  text-decoration: none;
  color: #fff;
  font-size: 0.8rem;
}
.mckw__login-back-link a span {
  position: relative;
  top: 8px;
  right: 5px;
}

@media screen and (max-width: 900px) {
  .mckw__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .mckw__login-back-link {
    margin-top: 1rem;
  }
  input {
    margin-bottom: 0;
  }
  button[type='submit'] {
    padding: 0.75rem;
  }
}
