.mckw__card-container {
  display: flex;
  flex-direction: row;
  margin: 6rem;
}
.mckw__card-container_A {
  flex: 0.75;
  margin-right: 2rem;
}
.mckw__card-container_B {
  flex: 1;
}
@media screen and (max-width: 900px) {
  .mckw__card-container {
    flex-direction: column;
  }
}
