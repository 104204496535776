.mckw__features-container__feature {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mckw__features-container__feature-title {
  flex: 1;
  margin-right: 2rem;
}

.mckw__features-container__feature-title h1 {
  font-family: var(--font-family);
  color: var(--color-card);
}
.mckw__features-container__feature-title h2 {
  font-family: var(--font-family);
  color: var(--color-card);
}

.mckw__features-container__feature-title div {
  width: 75px;
  height: 3px;
  background: var(--color-feature);
  margin-bottom: 1rem;
}

.mckw__features-container_feature-text {
  flex: 2;
  display: flex;
}

.mckw__features-container_feature-text p {
  font-family: var(--font-family);
  line-height: 24px;
  color: var(--color-card);
  margin-top: 1rem;
}
@media screen and (max-width: 500px) {
  .mckw__features-container__feature-title h2 {
    font-size: 1.7rem;
  }
}
