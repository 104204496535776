@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;500&display=swap');

:root {
  --font-family: 'Inter', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #405b23 1.84%, #deecdd 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #405b23 -13.86%, #deecdd 99.55%);
  --color-bg: #405b23;
  --color-navbut: #405b23;
  --color-navbutlight: #ffffff;
  --color-footer: #000000;
  --color-card: #022c08;
  --color-feature: #022c08;
  --color-text: #ffffff;
  --color-navbutlightgreen: #a0e4aa;
  --color-subtext: #000000;
  --color-layoutbg: #deecdd;
}
