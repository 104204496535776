.mckw__contact_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--color-card);
  padding: 6rem;
  align-items: center;
  flex-direction: row;
}
.mckw__contact_container_header {
  width: 100%;
  line-height: 40px;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
  margin-bottom: 2rem;
}
.mckw__contact_container_header h2 {
  line-height: 40px;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
  margin-bottom: 2rem;
}
.mckw__contact_container_form {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 50%;
  padding-right: 2rem;
  overflow: auto;
}
.mckw__contact-btn {
  text-align: center;
}

.no-to-pad {
  padding-top: unset;
}

FormInput {
  display: flex;
  flex-direction: column;
  height: 30px;
}

.mckw__contact-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mckw__contact-content__input[type='text'],
select,
input {
  width: 100%;
  padding: 1rem;
  border-radius: 20px;
  border: none;
  resize: vertical;
  font-family: var(--font-family);
  font-size: 0.8rem;
  color: var(--color-navbut);
  margin: 0.6rem 0;
}
.mckw__contact-content__textarea[type='text'] {
  width: 100%;
  padding: 1rem;
  border-radius: 20px;
  border: none;
  resize: vertical;
  font-family: var(--font-family);
  font-size: 0.8rem;
  color: var(--color-navbut);
  margin: 0.9rem 0;
}

label {
  padding: 0;
  display: inline-block;
  font-family: var(--font-family);
  color: var(--color-navbutlightgreen);
  margin-top: 0.7rem;
}
button[type='submit'] {
  padding: 1rem;
  color: var(--color-subtext);
  background: var(--color-navbutlightgreen);
  font-family: var(--font-family);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  margin-top: 2.5rem;
  font-size: 1rem;
}

button[type='submit']:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}
input:invalid[focused='true'] ~ p {
  display: block;
}
input:invalid[focused='true'] {
  border: 1px solid red;
}
textarea:invalid[focused='true'] {
  border: 1px solid red;
}
.mckw__contact-content_input p {
  display: none;
  color: red;
  font-size: 12px;
  font-family: var(--font-family);
  padding: 0.5rem 0;
}

form {
  margin-top: 1rem;
}
.contact_container_image {
  width: 50%;
}
.contact_container_image img {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .mckw__contact_container_form form {
    width: 100%;
  }

  .mckw__contact_container_header h2 {
    padding: 2rem 0 0 5.4rem;
  }
  .mckw__contact-title {
    font-size: 2.3rem;
  }
  .mckw__contact_container {
    flex-direction: column;
    padding: 0;
  }

  .contact_container_image {
    width: 60%;
    margin-left: -170px;
  }
  .contact_container_image img {
    display: none;
  }

  .mckw__card-container_A {
    margin-right: 0;
  }
  .mckw__contact-btn {
    margin-top: 1rem;
    margin-bottom: 3rem;
    text-align: left;
  }
  .mckw__card-container {
    margin: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .mckw__contact_container_header h2 {
    margin-left: -1.5rem;
  }
  .mckw__contact_container_form{
    width:75%;
  }
}
@media screen and (max-width: 600px) {
  .mckw__contact_container_header h2 {
    margin-left: -3rem;
  }
}
@media screen and (max-width: 500px) {
  .mckw__contact_container_header h2 {
    margin-left: -2.6rem;
  }
  
  .mckw__who-image img {
    width: 175px;
  }
}
