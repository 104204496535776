.mckw__navbar {
  /*position: fixed;*/
  top: 0.5rem;
  width: 100%;
  height: auto;
  z-index: 3000;
}

.mckw__navbar-links_container {
  display: flex;
  flex-direction: row;
  height: auto;
  z-index: 105;
  padding: 1rem 0 2rem 0;
  justify-content: flex-end;
  align-content: center;
}

.mckw__navbar-links_logo img {
  height: 39.04px;
  margin-right: 2rem;
  position: absolute;
  left: 3rem;
  top: 1.5rem;
  z-index: 106;
}

.mckw__navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.mckw__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mckw__linkitem {
  color: #fff;
  background-color: var(--color-navbut);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.25rem 1rem;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
  border: none;
  margin: 0.25rem 0.5rem;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mckw__navbar-links_container a:hover {
  background-color: var(--color-navbutlightgreen);
  color: var(--color-subtext);
  border-radius: 20px;
}

.mckw_login {
  background-color: var(--color-navbutlightgreen);
  color: var(--color-card);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.25rem 1rem;
  cursor: pointer;
  outline: none;
  border-radius: 20px;
  border: none;
  margin: 0.25rem 0.5rem;
  list-style: none;
}

.mckw_login:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
  padding: 0.25rem 1rem;
}

.mckw__navbar_btn button {
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;
  padding: 5px;
  color: #000;
  font-size: 18px;
}
.mckw__navbar_btn button:hover {
  background-color: #405b23;
  color: var(--color-navbutlight);
}

@media screen and (max-width: 1050px) {
  .mckw__navbar-menu {
    display: flex;
    background-color: var(--color-navbutlightgreen);
  }
  .mckw_login:hover {
    color: white;
  }
}

@media screen and (max-width: 900px) {
  a.mckw__linkitem:hover {
    color: white;
  }
  .mckw__navbar-links_container {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: var(--color-navbutlightgreen);
    z-index: 105;
    padding: 4rem 0 2rem 0;
    width: 100%;
  }
  .mckw__linkitem {
    border-radius: 0;
    background-color: unset;
    color: var(--color-subtext);
    justify-content: unset;
    padding-left: 2.4rem;
  }
  .mckw__navbar-links_logo img {
    left: 0;
  }
  .mckw_login,
  .mckw_login:hover {
    padding-left: 2.4rem;
  }

  .mckw__navbar_btn button {
    display: block;
    padding: 0.25rem 1rem;
    color: var(--color-subtext);
    background: var(--color-navbutlightgreen);
    font-family: var(--font-family);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    width: auto;
    font-size: 14px;
    z-index: 2000;
    margin: 1rem;
  }
}
